.AsideComp{
    justify-content: center;
    background-color: white;
    margin: 50px auto;
    padding: 10px 0;
}
.AsideComp:hover{
    background-color: #efefef;
    cursor: pointer;
}
.AsideComp img{
    height: 400px;
    width: 100%;
    min-width: 350px;
    box-shadow: 2px 8px 12px rgb(0 0 0/ 60%);
    border-radius: 2px;
}
.aside-left-content, .aside-right-content{
    padding: 10px;
    width: 600px;
}
.aside-right-content{
}
.AsideComp p{
    font-size: 1.5rem;
    line-height: 60px;
    text-align: justify;
}
.AsideComp h3{
    text-align: center;
}
@media screen and (max-width: 600px) {
    .AsideComp{
        flex-direction: column;
    }
    .aside-left-content, .aside-right-content{
        padding: 2px;
        width: 95%;
    }
    .AsideComp p{
        line-height: 30px;
    }
}