.AsideListing {}
.AsideListing img{
    box-shadow: none !important;
}
.AsideComp  h2{
}
.AsideComp ul li{
    padding: 5px 0;
    display: flex;
    gap: 10px;
}
