.Error404 {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Error404 h1{
    font-size: 2.5rem;
}
.Error404 p{
    font-size: 1.5rem;
}