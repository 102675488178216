/**GENERAL*/
body{
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}
h1{
  font-size: 2.5rem;
}
h2{
  font-size: 2.25rem;
}
h3{
  font-size: 2.0rem;
}
h4{
  font-size: 1.75rem;
}
h5{
  font-size: 1.5rem;
}
h6{
  font-size: 1.25rem;
}
/** FLEX **/
.flex{
  display: flex;
}
.wrap{
  flex-wrap: wrap;
}
.nowrap{
  flex-wrap: nowrap;
}
.flex-1{
  flex: 1;
}
.flex-2{
  flex: 2;
}
.flex-3{
  flex: 3;
}
.flex-4{
  flex: 4;
}
.flex-5{
  flex: 5;
}

.gap-5{
  gap: 5px;
}
.gap-10{
  gap: 10px;
}
.gap-15{
  gap: 15px;
}
.gap-20{
  gap: 20px;
}
.gap-25{
  gap: 25px;
}
.gap-30{
  gap: 30px;
}
.jcc,.flex-center-h{
  justify-content: center;
}
.column{
  flex-direction: column;
}

.row{
  flex-direction: row;
}
.tc{
  text-align: center;
}
.cp{
  cursor: pointer;
}
.aic, .flex-center-v{
  align-items: center;
}
/** POSITION **/
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.fixed{
  position: fixed;
}
.sticky{
  position: sticky;
}
/** DISPLAY **/
.none{
  display: none;
}
.block{
  display: block;
}
/** TIPOGRAPHIE **/
.box{
  box-shadow: 2px 8px 12px rgb(0 0 0 / 20%);
}
.bold{
  font-weight: bold;
}
/** LISTES */
ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

/** MARGIN */
.m-5{
  margin: 5px;
}
.m-10{
  margin: 10px;
}
.m-15{
  margin: 15px;
}
.m-20{
  margin: 20px;
}
.m-25{
  margin: 25px;
}
/** PADDING */
.p-5{
  padding: 5px;
}
.p-10{
  padding: 10px;
}
.p-15{
  padding: 15px;
}
.p-20{
  padding: 20px;
}
.p-25{
  padding: 25px;
}
i{
  cursor: pointer;
  color: var(--main-color-gray);
  font-size: 1.3rem;
}
/* Icon */
.dropdown::after{
  content: "\1F893";
  padding-left: 2px;
  position: relative;
  top: 5px;
}
.burger-icon::after{
  content: '\002630';
  float: right;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

/******************* MAIN **********************/
:root{
  --main-color: #1c1d1f;
  --main-color-two: teal;
  --main-color-pur: #2b2b2b;
  --main-color-white: white;
  --main-color-black: black;
  --main-color-gray: gray;
  --main-color-orange: orange;
}
header{
  height: 8vh;
  box-shadow: 2px 8px 12px rgb(0 0 0 / 20%);
  background-color: var(--main-color-white);
  padding: 0 20px;
  font-weight: bold;
  position: sticky;
  top: 80px;
  z-index: 999;
}
.content{
  
}
/*************** COURSE *******************/
.logo a{
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--main-color-two) ;
}
.banner{
  background-color: var(--main-color-orange);
  color: var(--main-color-white);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 999;
}
.courses{
  padding: 15px;
  justify-content: center;
}
.courses-item img{
  width: 100%;
  height: 200px;
}
.courses-item .course-name{
  height: 65px;
}
.content-title p{
  font-size: 1.2rem;
}

.header-center{
  height: 50px;
}
.header-center .fa-search{
  top: 16px;
  left: 12px;
  font-size: 1.3rem;
  color: var(--main-color-gray);
}
.header-center .autocomplete{
  left: 0;
  top: 64px;
  width: 100%;
  background-color: var(--main-color-white);
}
.autocomplete .autocomplete-item{
  font-size: 1.2rem;
  font-weight: 100;
  padding: 10px;
}
.autocomplete .autocomplete-item:hover{
  background-color: #efefef;
}
.header-center input{
  border-radius: 50px;
  border: 0.1px solid var(--main-color-black);
  outline: none;
  padding-left: 40px;
  font-size: 1.2rem;
}
.courses-item h3{
  font-size: inherit;
  margin: 0;
}
a{
  color: var(--main-color-black);
  text-decoration: none;
}
.courses-item{
  width: 320px;
  height: 410px;
  box-shadow: 2px 8px 12px rgb(0 0 0 / 20%);
}
.courses-item:hover{
  border: 2px solid var(--main-color);
  background-color: var(--main-color);
  color: var(--main-color-white);
}
.courses-description{
  padding: 10px;
  height: 100%;
}
.course-price{
  font-size: 2rem;
  font-weight: bold;
}
.course-sold-price{
  font-size: 2.5rem;
}
.fa-star{
  color: var(--main-color-orange);
}
.slider{
  width: 90%;
  height: 500px;
  /*background-image: url("/assets/images/d93015d2-3ec1-48cd-97c6-60eace42b20c.jpg");*/
  background-size: cover;
  background-position-x: 62%;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.slider-content{
  position: absolute;
  top: 350px;
  left: 150px;
  background-color: var(--main-color-white);
  width: 500px;
  padding: 10px;
}
.slider-content h2{
  font-size: 2.6em;
  padding: 0;
  margin: 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.slider-content  p{
  font-size: 1.3rem;
}
/*************************** SUB MENU *************/
.sub-menu{
  background-color: var(--main-color-white);
  top: 8vh;
  left: 100px;
  width: 300px;
  cursor: pointer;
  
}
.sub-menu div{
  padding: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 1.2rem;
}
.sub-menu div:hover{
  font-weight: bold;
  background-color: #efefef;
}

/*********************** Footer ******************/
footer{
  
  background-color: var(--main-color);
  box-shadow: 2px 8px 12px rgb(0 0 0 / 20%);
}
.footer-title{
  text-align:  center;
  color: var(--main-color-white);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid var(--main-color-white);
}
.footer-content{
  height: 100%;
  margin: 0 auto;
  width: 80%;
  height: 30vh;
  justify-content: space-between;
  padding: 10px 0;
}
.footer-item{
  padding: 10px 0;
}
.footer-item a{
  color: var(--main-color-white);
  font-weight: bold;
}
.footer-item a:hover{
  text-decoration: underline;
}
.footer-link{
  color: var(--main-color);
}
.page{
  width: 90%;
  margin: 0 auto;
 
}
.privacy-content a{
  color: var(--main-color-two);
  font-weight: bold;
}
.page h2{
  text-align: center;
}
.page p{
  font-size: 1.2rem;
  line-height: 1.9;
}
.page ul{
  padding-bottom: 30px;
  display:  flex;
  flex-direction: column;
}
.page li{
  padding: 10px 0;
  display:  flex;
  gap: 10px;
  font-size: 1.3rem;
  cursor: pointer;
}
.privacy-content div{
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 10px;
  text-align: justify;
}
.footer-bottom{
  background-color: var(--main-color-pur);
  color: var(--main-color-white);
  padding: 20px 0;
  text-align: center;
  font-weight: bold;
}
/****************** MODAL ****************/
.overlay{
  top: 0;
  left: 0;
  background-color: var(--main-color);
  width: 100%;
  height: 100vh;
  opacity: 0.9;
  z-index: 9999;
}
.modal{
  background-color: var(--main-color-white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: 600px;
  padding: 15px;
  text-align: center;
  box-shadow: 2px 8px 12px rgb(0 0 0 / 60%);
}
.modal .modal-content{
  padding: 20px;
  font-size: 1.4rem;
}
.modal-footer{
  
}
.modal-footer button{
  height: 50px;
  border: none;
  outline: none;
  color: var(--main-color-white);
  font-size: bold;
  background-color: red;
  font-size: 1.5rem;
}
.fa-times{
  top: 0;
  right: 10px;
  font-size: 1.4rem;
  cursor: pointer;
  color: var(--main-color-gray);
}
/****************** SEARCH BAR CONTAINER *******************/
.search-container{
  background-color: var(--main-color-white);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.search-bar-container{
  border-bottom: 0.1px solid var(--main-color-gray);
}
.search-bar-container input{
  height: 40px;
  border: none;
  outline: none;
  font-size: 1.3rem;
}
.search-container .autocomplete{
  overflow-y: auto;
}
@media screen and (max-width: 900px) {
  .slider-content{
      position: static;
      left: 0;
      top: 0;
      right: 0;
      width: auto;
      box-shadow: 2px 8px 12px rgb(0 0 0 / 20%);

  }
  .footer-content{
    flex-direction: column;
    height: auto;
    text-align: center;
  }
  .footer-title{
    height: auto;
    padding: 5px;
  }
  .banner{
      height: auto;
  }
  .slider{
    width: 100%;
    background-position-x: 75%;
  }
}
@media screen and (max-width: 600px) {
  .header-center, .header-left .nav-item{
      display: none;
  }
  .header-left i{
    display: flex;
    color: var(--main-color-gray);
    font-size: 1.5rem;
  }
  .logo{
    flex:1;
    text-align: center;
  }
  .header-right{
      display: none;
  }
  .banner{
      height: auto;
      font-size: 0.8rem;
      padding: 10px;
      position: static;
  }
  header{
    top: 0;
  }
  
  .slider-content h2{
    font-size: 1.8rem;
  }
  .modal{
      width: 90%;
  }
}